import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid } from 'react-loader-spinner';
import { ProductSearch } from '../../services/dmpApi';
import Dropdown from '../Dropdown/Dropdown';
import './Form.scss';
import './FormComponent.scss';
import FormInput from './FormInput';
import FormTextArea from './FormTextArea';


const FormAddToMarketplace = ({ id, onFieldChange, passClearStateFunction, passValidateFormFunction }) => {
    const requestDateLabel = 'Date of Request'
    const dropdownRequestLabel = 'Select one request type'
    const dropdownReportLabel = 'Select a report'
    const summaryLabel = 'Please elaborate on the option you chose above.'
    const requestedForLabel = 'Who are you requesting for?'
    

    const _initialState = {
        requestDate: { prompt: requestDateLabel, value: new Date() },
        dropdownRequestType: {prompt: dropdownRequestLabel, value: ''},
        dropdownReportType: {prompt: dropdownReportLabel, value: ''},
        summary: { prompt: summaryLabel, value: '' },
        requestedFor: { prompt: requestedForLabel, value: '' }
    }
    const [summarizeError, setSummarizeError] = useState(false);
    const [dropdownRequestError, setDropdownRequestError] = useState(false);
    const [dropdownReportError, setDropdownReportError] = useState(false);
    const [requestedForError, setRequestedForError] = useState(false);
    const [fieldLocalState, setFieldLocalState] = useState(_initialState);
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState('');
    const [hideFields, setHideFields] = useState(false);

    const clearState = () => {
        setSummarizeError(false);
        setDropdownRequestError(false);
        setDropdownReportError(false);
        setRequestedForError(false);
        setFieldLocalState(_initialState);
        setHideFields(false);
    }
    passClearStateFunction(clearState)

    function LoadData() {
        setIsLoading(true);
        ProductSearch()
            .then(response => {
                if (response !== undefined && response.data !== undefined && response.data.results !== undefined) {
                    setSearchResults(response.data.results);
                    if (loadError.length > 0) setLoadError('');
                } else {
                    setLoadError('Error gathering Search results. ' + response);
                    console.log('Search Error', response);
                }
                setIsLoading(false);
            }).catch(error => {
                setLoadError('Error loading Search results. ' + error);
                setIsLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        LoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateForm = () => {
        let isValid = true;
        if (!hideFields && fieldLocalState.summary.value === '') {
            setSummarizeError(true);
            isValid = false;
        }
        if (fieldLocalState.dropdownRequestType.value === '') {
            setDropdownRequestError(true);
            isValid = false;
        }
        if (fieldLocalState.dropdownRequestType.value==='PowerBI Access Request' && fieldLocalState.dropdownReportType.value===''){
            setDropdownReportError(true);
            isValid = false;
        }
        if (!hideFields && fieldLocalState.requestedFor.value === '') {
            setRequestedForError(true);
            isValid = false;
        }
        return isValid;
    }
    passValidateFormFunction(validateForm);

    const handleFieldChange = (key, value) => {
        if (key === 'summary') {
            value === '' ? setSummarizeError(true) : setSummarizeError(false);
        }
        if (key === 'dropdownRequestType') {
            value === '' ? setDropdownRequestError(true) : setDropdownRequestError(false);
            if(value==='Log Data Issue/Question' || value==='Data Lake Ingestion Request' || value==='Product Enhancement Request' || value==='Data Lake Access Request'){
                setHideFields(true);
            }else{
                setHideFields(false);
            }
        }
        if(key === 'dropdownReportType') {
            value === '' ? setDropdownReportError(true) : setDropdownReportError(false);
        }
        if (key === 'requestedFor') {
            value === '' ? setRequestedForError(true) : setRequestedForError(false);
        }

        fieldLocalState[key].value = value;
        setFieldLocalState(fieldLocalState);
        onFieldChange(fieldLocalState);
    }
    function errorResult() {
        return (
            <div data-testid='search-page-error' onClick={() => LoadData()} className='loading-error'>
                <span>{loadError}<br />
                    Click here to retry</span>
            </div>
        );
    }

    function loadingResult() {
        return (
            <div className="search-spinner">
                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                <span>Searching....</span>
            </div>
        );
    }
    return (
        <>
            <label htmlFor=''>{requestDateLabel}</label>
            <DatePicker
                disabled={true}
                selected={fieldLocalState.requestDate.value}
                onChange={(date) => handleFieldChange('requestDate', date)}
                value={fieldLocalState.requestDate.value}
                placeholderText='MM/DD/YYYY' />
            <label htmlFor=''>{'What type of request are you submitting?'}</label>
            <Dropdown
                id="dropdown request"
                label={dropdownRequestLabel}
                onChange={(option) => handleFieldChange('dropdownRequestType', option)}
                errorMessage='This Field is required'
                showError={dropdownRequestError}
                options={['Product Enhancement Request',
                    'Data Lake Ingestion Request',
                    'Data Lake Access Request', 
                    'New Project Idea',
                    'Log Data Issue/Question',
                    'PowerBI Access Request']} 
            />
            {fieldLocalState['dropdownRequestType'].value==='PowerBI Access Request' && <label htmlFor=''>{'Which report you need access for?'}</label>}
            {fieldLocalState['dropdownRequestType'].value==='PowerBI Access Request' ?
                (loadError && loadError.length > 0) ? errorResult() :
                    ((isLoading) ? loadingResult() :
                    <Dropdown
                    id="dropdown report"
                    label={dropdownReportLabel}
                    onChange={(option) => handleFieldChange('dropdownReportType', option)}
                    errorMessage='This Field is required'
                    showError={dropdownReportError}
                    options={searchResults.filter(d => d.type==="Dashboard" && !d.hasAccess).map(d => d.name).sort()} 
                />    
                    )
            : <></>}
            {!hideFields && <FormTextArea
                id={`${id}-summarize`}
                type="textarea"
                errorMessage='This Field is required'
                required={true}
                rows="4"
                cols="50"
                name="summary"
                long={true}
                label={summaryLabel}
                value={fieldLocalState.summary.value}
                placeholder="Summarize data needed for ingestion to SaraS."
                handleChange={(e) => handleFieldChange('summary', e.target.value)}
                showError={summarizeError} />}
            {!hideFields && <FormInput
                id={`${id}-dataUsedFor`}
                type="text"
                errorMessage='This Field is required'
                showError={requestedForError}
                name="requestedFor"
                label={requestedForLabel}
                placeholder="List the full name(s)"
                value={fieldLocalState.requestedFor.value}
                handleChange={(e) => handleFieldChange('requestedFor', e.target.value)} />}
            <div className='form--header--secondary'>
                Risk, Restrictions, and Conditions of Use
            </div>
            <label className='label-long question mb-6'>
                Data Privacy and Data Security will be engaged if there are PII and/or PCI data identified
            </label>
        </>
    )
}

FormAddToMarketplace.propTypes = {
    id: PropTypes.string,
    onFieldChange: PropTypes.func,
    passClearStateFunction: PropTypes.func,
    passValidateFormFunction: PropTypes.func
};

export default FormAddToMarketplace;