import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import './ProductDescription.scss';

const ProductDescription = ({ id, description }) => {
    const [isReading, setIsReading] = useState(false);

    const [isOverflowing, setIsOverflowing] = useState(false);
    const heightRef = useRef(null);

    requestAnimationFrame(() => {
        const el = heightRef.current;
        if (el && el.offsetHeight < el.scrollHeight){
            setIsOverflowing(true);
        }
    })

    const readingToggle = () => {
        setIsReading(current => !current)
    }

  return (
    <div id={id} data-testid={id} className='description-container'>
        <div ref={heightRef} className={isReading ? 'description show-more' : 'description'} dangerouslySetInnerHTML={{ __html: description }}>
        </div>
        {isOverflowing ? <div data-testid={`${id}-read-button`} onClick={readingToggle} className={isReading ? 'read-button read-less' : 'read-button'}>{isReading ? <span>Read less</span> : <><span className='ellipsis-overflow'>...&nbsp;&nbsp;</span><span className='continue-reading'>Continue reading</span></>}</div> : null}
    </div>
  )
}

ProductDescription.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string
}

export default ProductDescription